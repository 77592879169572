import { ApolloError } from '@apollo/client';

export function truncateString(str: string, n: number) {
  if (str) {
    return str.length > n ? str.substr(0, n - 1) + '...' : str;
  }
  return str;
}

export enum SizeEnum {
  xs,
  sm,
  md,
  lg,
  xl,
}

export enum NotificationTypes {
  success = 'success',
  error = 'error',
  pending = 'pending',
}

export const formatCurrency = (amount: number) => {
  return Number(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,');
};

export const getStoreCurrencyFormatter = (
  currency: string = 'USD',
  value: number | string = 0,
) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
  });
  const number = Number(value);
  return formatter.format(number);
};

export const getStoreCurrencySymbol = (currency: string | undefined) =>
  getStoreCurrencyFormatter(currency, 0).charAt(0);

export const apiErrorHandler = (error: ApolloError) => {
  let updatedError = { ...error };

  if (
    error?.graphQLErrors &&
    error?.graphQLErrors[0]?.extensions?.code === 'invalid-jwt'
  ) {
    updatedError.message =
      'Your session has expired, the page will refresh in 5 seconds to reauthenticate you';

    setTimeout(() => window.location.reload(), 5000);
    return updatedError;
  }

  return error;
};

export const validateEmail = (email: string) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};
