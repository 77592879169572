import React, { Suspense } from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { NavigationListener } from 'components/NavigationListener';
import { NhostClient } from '@nhost/react';
import { Home } from 'containers/Home';
import { Login } from './containers/Login';

const NhostApolloProvider = React.lazy(() =>
  import('@nhost/react-apollo').then((module) => ({
    default: module.NhostApolloProvider,
  })),
);

const NhostReactProvider = React.lazy(() =>
  import('@nhost/react').then((module) => ({
    default: module.NhostProvider,
  })),
);

const nhost = new NhostClient({
  subdomain: process.env.REACT_APP_NOST_SUBDOMAIN,
  region: process.env.REACT_APP_NHOST_REGION,
});

const App = () => {
  return (
    <Suspense fallback={<div></div>}>
      <NhostReactProvider nhost={nhost}>
        <NhostApolloProvider nhost={nhost}>
          <BrowserRouter>
            <NavigationListener />
            <Routes>
              <Route path="login" element={<Login />} />
              <Route path="/:id" element={<Home />} />
              <Route path="/" element={<Home />} />
            </Routes>
          </BrowserRouter>
        </NhostApolloProvider>
      </NhostReactProvider>
    </Suspense>
  );
};

export default App;
