import gql from 'graphql-tag';

export const GET_STORES = gql`
  query stores {
    stores {
      id
      name
      plan {
        active
      }
      store
      shouldUpdateAppInstall
      domains {
        id
        domain
      }
      completedSteps
    }
  }
`;

/************
 *
 * Tracking query
 * **********
 */
export const GET_TRACKING_DETAILS = gql`
  query tracking($input: QueryGetTrackingInput!) {
    getTracking(input: $input) {
      storeName
      orderId
      orderNumber
      customerEmail
      tracking {
        trackingNumber
        trackingCompany
        url
        items {
          productTitle
          orderItemId
          quantity
        }
      }
    }
  }
`;

export const GET_CUSTOM_TRACKING_DETAILS = gql`
  query getCustomTrackingPortalContent(
    $input: QueryGetCustomTrackingPortalContentInput!
  ) {
    getCustomTrackingPortalContent(input: $input) {
      html
      trackingCompany
      trackingNumber
    }
  }
`;

/************
 *
 * ORDERS query
 * **********
 */

export const GET_ORDERS = gql`
  query orders(
    $storeId: uuid!
    $limit: Int
    $offset: Int
    $order_by: [orders_order_by]!
  ) {
    orders(
      where: { storeId: { _eq: $storeId } }
      limit: $limit
      offset: $offset
      order_by: $order_by
    ) {
      isReorder
      cancelledAt
      number
      orderId
      protectionStatus
      protectionTotal
      total
      customerName
      customerEmail
      createdAt
      id
      subtotal
      claims {
        id
        number
      }
      items {
        id
        trackingId
        title
      }
    }
    ordersAggregate(where: { storeId: { _eq: $storeId } }) {
      aggregate {
        count
      }
    }
  }
`;

/************
 *
 * STORE queries
 * **********
 */

export const GET_STORE_SETTINGS = gql`
  query storesSettings($customDomain: String!) {
    storesSettings(where: { customDomain: { _eq: $customDomain } }) {
      customDomain
      trackingPortalCoverImage
    }
  }
`;

export const GET_STORE_PROPERTIES = gql`
  query store($storeId: uuid!) {
    store(id: $storeId) {
      users {
        user {
          avatarUrl
          displayName
          email
          id
        }
        role
      }
      shouldUpdateAppInstall
      completedSteps
      contactEmail
      contactPhone
      createdAt
      currency
      id
      name
      invites {
        email
        user {
          displayName
        }
      }
      logoUrl
      bannerUrl
      policyUrl
      requireDeliveryGuaranteePolicy
      plan {
        active
        chargeUrl
        chargeConfirmed
        createdAt
        endDate
        id
        startDate
        storeId
        planId
      }
      protectionSettings {
        protectionType
        variantIds
      }
      store
      type
      itemFulfillmentImmediate
      widgetAutoOptIn
      widgetShowCart
      excludedProductSkus
      uninstalledAt
      trialEndsAt
    }
  }
`;
