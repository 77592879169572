/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { LoadingScreen } from 'components/LoadingScreen';
import BlueBox from '../assets/images/blue-box.jpg';
import FormInput from '../components/FormInput';
import { Button } from '../components/Button';
import { SizeEnum } from '../utils';
import TrackingFooter from '../components/TrackingFooter';
import classes from '../modulecss/trackingPortalPage.module.css';
import InnerHTML from './HOC/DangerouslySetHtmlContent';

import { GET_CUSTOM_TRACKING_DETAILS, GET_STORE_SETTINGS } from 'gql/queries';

export function Home() {
  const params = useParams();

  const [loading, setLoading] = useState(false);
  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [trackingOrOrderNumber, setTrackingOrOrderNumber] = useState('');
  const [storeSettingsData, setStoreSettingsData] = useState<any | null>(null);
  const [data, setData] = useState<any | null>(null);
  const [currentTracking, setCurrentTracking] = useState<any | null>(null);

  const [errors, setErrors] = useState<string | null>(null);

  const currentTemplate = currentTracking ? currentTracking?.html : null;

  const [getTrackingDetails] = useLazyQuery(GET_CUSTOM_TRACKING_DETAILS, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      setLoading(false);
      if (data?.getCustomTrackingPortalContent?.length) {
        const mappedData = data?.getCustomTrackingPortalContent.map(
          (r: any, ind: any) => {
            return { ...r, id: ind + 1 };
          },
        );
        setData(mappedData);
        setCurrentTracking(mappedData[0]);
      } else if (data?.errors?.length) {
        setErrors(data.errors[0]?.message);
      } else {
        setErrors('No tracking details found. Please try later.');
      }
      document.body.classList.remove(classes.pageBody);
    },
    onError(error) {
      setLoading(false);
      setErrors(
        error?.message ?? 'No tracking details found. Please try later.',
      );
      document.body.classList.remove(classes.pageBody);
    },
  });

  const [getStoreSettings] = useLazyQuery(GET_STORE_SETTINGS, {
    onError(error) {
      setLoading(false);
    },
    onCompleted(data) {
      if (data.storesSettings.length) {
        setStoreSettingsData(data.storesSettings[0]);
      }
      setLoading(false);
    },
  });

  const getCustomTrackingData = () => {
    setLoading(true);
    document.body.classList.add(classes.pageBody);
    getTrackingDetails({
      variables: {
        input: {
          emailOrPhone,
          trackingOrOrderNumber,
        },
      },
    });
  };

  useEffect(() => {
    if (params?.id) {
      if (params.id && params.id !== '') {
        setLoading(true);
        document.body.classList.add(classes.pageBody);
        getTrackingDetails({
          variables: {
            input: {
              emailOrPhone,
              trackingOrOrderNumber: params.id,
            },
          },
        });
      }
    }
  }, [params.id]);

  useEffect(() => {
    const subdomain = window?.location?.hostname
      ?.split('.')
      .filter((part) => part !== 'www')
      .shift();
    // const subdomain = 'test'
    console.log('subdomain', subdomain);
    if (
      !['track', 'staging-track-shipaid', 'localhost'].includes(
        subdomain ?? '',
      ) &&
      !params?.id
    ) {
      setLoading(true);
      getStoreSettings({
        // fetchPolicy: 'cache-and-network',
        variables: {
          customDomain: subdomain,
        },
      });
    }
  }, []);

  if (loading) return <LoadingScreen />;

  if (data && data?.length) {
    return (
      <div className={classes.container}>
        <div className={classes.firstContent}>
          {data?.length > 1 ? (
            <div className={classes.buttonContainer}>
              {data.map((rec: any, index: any) => (
                <button
                  key={index}
                  className={`${classes.shipmentButton} ${
                    rec.id === currentTracking?.id
                      ? classes.shipmentButtonSelected
                      : null
                  }`}
                  onClick={() => setCurrentTracking(rec)}
                >
                  Shipment #{index + 1}
                </button>
              ))}
            </div>
          ) : null}
          <main className={classes.content}>
            <InnerHTML
              className={classes.templateContainer}
              html={currentTemplate || '<div></div>'}
            />
          </main>
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col max-h-screen  md:my-0 justify-between">
      <div className="my-10 h-full max-w-6xl mx-auto form-section">
        <div className="block md:grid gap-8   items-center justify-between grid-cols-2">
          <div className="mx-auto pt-8">
            <img
              className="w-full mx-auto rounded-lg"
              src={
                storeSettingsData?.trackingPortalCoverImage
                  ? storeSettingsData.trackingPortalCoverImage
                  : BlueBox
              }
              alt="Box"
            />
          </div>
          <div className="span-1 px-8 my-20">
            <h1 className="text-3xl font-bold text-[rgb(2, 54, 70)] mb-4">
              Track your order
            </h1>
            {!storeSettingsData?.customDomain ? (
              <p className="font-light text-[14px] md:text-base py-4">
                Check the status of your order and track every step from your
                favorite brand to your door. Simply enter your order number and
                email or tracking code and we will get you the latest update!
              </p>
            ) : null}
            <div className="my-4">
              <form className="my-12">
                <div>
                  <h5 className="font-light text-[rgb(2, 54, 70)] mb-4">
                    Please enter your Email and Tracking Number or Order ID
                    below to check the status of your order
                  </h5>
                  <div className="mb-4">
                    <FormInput
                      id="emailOrPhone"
                      name="emailOrPhone"
                      // label="Email Or Phone"
                      label=""
                      value={emailOrPhone}
                      onChange={(e) => setEmailOrPhone(e.target.value)}
                      placeholder="Email Or Phone"
                      //type="email"
                      type="text"
                    />
                  </div>
                  <div className="mb-4">
                    <FormInput
                      id="trackingOrOrderNumber"
                      name="trackingOrOrderNumber"
                      // label="Track by Order Id Or Tracking Number"
                      label=""
                      value={trackingOrOrderNumber}
                      onChange={(e) => setTrackingOrOrderNumber(e.target.value)}
                      placeholder="Tracking Number Or Order Number (Required)"
                      type="text"
                    />
                  </div>
                  <Button
                    size={SizeEnum.xl}
                    isRounded
                    // type="submit"
                    onClick={() => getCustomTrackingData()}
                    disabled={
                      emailOrPhone === '' && trackingOrOrderNumber === ''
                    }
                    className="bg-shipaidBlue text-white w-full rounded-full flex justify-center items-center align-middle text-center mt-4 hover:bg-opacity-80"
                  >
                    Track
                  </Button>
                  {errors !== '' ? (
                    <div className="text-md text-red-500 text-center m-2">
                      {errors}
                    </div>
                  ) : null}
                </div>
              </form>
            </div>
            <div></div>
          </div>
        </div>
      </div>
      <TrackingFooter isHomePage={true} />
    </div>
  );
}
