/* eslint-disable react/prop-types */
import Logo from '../assets/images/logo.png';
import classes from '../modulecss/trackingFooter.module.css';

const TrackingFooter = ({ isHomePage = false }) => {
  return (
    <div className={isHomePage ? classes.containerHomePage : classes.container}>
      <img src={Logo} alt="logo" style={{ height: 25, width: 'auto' }} />
    </div>
  );
};

export default TrackingFooter;
